<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div>
    <div class="inquiry-wrapper" v-if="order">
      <div class="add-property-title row align-items-baseline me-auto">
        <div class="col-12"> {{ $t('vendor-order-details') }}</div>
        <div class="order-info-container">
          <div class="order-number-id"> {{ $t('vendor-order-number') }}: {{ order.id }}</div>
          <div class="order-info-wrapper">
            <div class="order-info-title"> {{ $t('vendor-client') }}:</div>
            <div class="order-info-item"> {{ $t('vendor-client-name') }}: {{ order.name }}</div>
            <div class="order-info-item"> {{ $t('vendor-client-phone') }}: {{ order.phone }}</div>
            <div class="order-info-item"> {{ $t('vendor-client-email') }}: {{ order.email }}</div>
          </div>
          <div class="order-info-wrapper">
            <div class="order-info-title"> {{
                order.deliveryType === 1 ? $t('delivery-to-address') : $t('delivery-to-courier-office')
              }}:
            </div>
            <div class="order-info-item"> {{ order.deliveryAddress }}</div>
            <div class="order-info-item"> {{ $t('vendor-order-date') }}: {{ order.createdAt }}</div>
          </div>
          <div class="order-info-wrapper" v-if="order.hasOwnProperty('invoiceDetails')">
            <div class="order-info-title"> {{ $t('vendor-invoice-details') }}:</div>
            <div class="order-info-item"> {{ $t('vendor-client-company-name') }}: {{
                order.invoiceDetails.copmanyName
              }}
            </div>
            <div class="order-info-item"> {{ $t('vendor-client-company-id') }}: {{ order.invoiceDetails.eik }}</div>
            <div class="order-info-item"> {{ $t('vendor-client-company-vat') }}: {{
                order.invoiceDetails.companyId
              }}
            </div>
            <div class="order-info-item"> {{ $t('vendor-client-company-address') }}:
              {{ order.invoiceDetails.companyAddress }}
            </div>
            <div class="order-info-item"> {{ $t('vendor-client-point-of-contact') }}: {{
                order.invoiceDetails.accountablePerson
              }}
            </div>
          </div>
          <div class="order-info-wrapper">
            <div class="order-info-title"> {{ $t('payment-method') }}:</div>
            <div class="order-info-item"> {{ $t('payment-method-' + order.paymentType) }}</div>

          </div>
          <div class="order-info-wrapper">
            <div class="order-info-title"> {{ $t('vendor-ordered-products') }}:</div>
            <div class="row labels-order d-none d-md-flex g-0">
              <div class="order-id col-1">{{ $t('vendor-product-id') }}</div>
              <div class="client-name col-4">{{ $t('vendor-products') }}</div>
              <div class="client-phone ms-auto col-2">{{ $t('vendor-price') }}</div>
              <div class="order-status col-1 ">
                {{ $t('vendor-quantity') }}
              </div>
            </div>
            <div class="inquiry-item" v-for="product in order.products">
              <AdminVendorListProduct
                  :id="product.id"
                  :type="'order-product-list'"
                  :date="product.date"
                  :category="product.category"
                  :title="product.title"
                  :visible="product.visible"
                  :price="product.price"
                  :image="product.mainImage.src"
                  :quantity="order.productQuantities[product.id]"
              />
            </div>
          </div>
          <div class="order-info-wrapper">
            <div class="order-info-title"></div>
          </div>
          <div class="order-status-title"> {{ $t('vendor-order-status') }}:</div>
          <div class="col-md-4" v-if="$store.state.user.info.type === 'vendor'">
            <Dropdown v-model="order.status" :selected-prop="order.status" :label="translation.choose_status" :options="orderStatusOptions"/>
          </div>
          <div class="col-md-4" v-else>
            {{ $t('order-status-' + order.status) }}
          </div>
          <div class="button-save col-md-3">
            <Button :theme="'solid-green'" :size="'medium'" :text="$t('save')" @click="updateOrderStatus()"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
